import React from "react"
import Layout from "../../components/layout"
import { OutboundLink } from "gatsby-plugin-google-analytics"

const ParkingGuidancePage = ({ data }) => {
  return (
    <Layout>
      <h1>Parking permit application guidance</h1>
      <div style={{ float: `left`, maxWidth: `800px` }}>
        <p>Please use either of the following examples to guide your own parking permit request, and to help you meet the requisite requirements of the <OutboundLink href="https://drive.google.com/file/d/1r6EcmWSWR_kHqrZv2BWUaqYb9GaL15QD/view?usp=share_link">permit policy</OutboundLink>.</p>

        <p><b>Tenants or property managers must ask the property owner to submit the request.</b></p>

        <h2>For an additional permit</h2>

        <blockquote>
          <p>Subject: Additional permit request<br/>
            To: <a href="mailto:ndunlop@trinityfactors.co.uk?subject=Additional%20permit%20request">
            ndunlop@trinityfactors.co.uk
          </a></p>
          <p>I'd like to request a second parking permit for my property at [address].</p>
          <p>As owner of the property I permit Trinity Factors to charge the cost of the additional permit (£10) to my factoring account.</p>
          <p>I understand the additional permit will be delivered direct to my property address within Caledonian Village.</p>
        </blockquote>

        <h2>For a replacement permit</h2>

        <blockquote>
        <p>Subject: Replacement permit request<br/>
            To: <a href="mailto:ndunlop@trinityfactors.co.uk?subject=Replacement%20permit%20request">
            ndunlop@trinityfactors.co.uk
          </a></p>
          <p>I'd like to request a replacement parking permit for my property at [address].</p>
          <p>[Explanation of what happened to the permit]</p>
          <p>As owner of the property I permit Trinity Factors to charge the cost of the replacement permit (£25) to my factoring account.</p>
          <p>I understand the replacement permit will be delivered direct to my property address within Caledonian Village.</p>
        </blockquote>
      </div>
    </Layout>
  )
}

export default ParkingGuidancePage
